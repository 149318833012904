import React, { useContext, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import Flex from "../../../components/Box/Flex";
// import { LogoIcon, LogoWithTextIcon } from "../../../components/Svg";
import { LogoImg, LogoWithTextImg } from "../../../components/Image";
import { MenuContext } from "../context";

interface Props {
  href: string;
  logoConfig: logoConfig;
  isDark: boolean;
}
export interface logoConfig {
  logoSrc: string;
  logoWithTextSrc: string;
  logoCommunityConjunctionSrc: string;
  logoCommunityWithTextSrc: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;
// .mobile - icon {
//   width: 32px;
//     ${ ({ theme }) => theme.mediaQueries.lg } {
//     display: none;
//   }
// }
//   .desktop - icon {
//   width: 160px;
//   display: none;
//   display: block;
//     ${ ({ theme }) => theme.mediaQueries.lg } {
//     display: block;
//   }
// }
const StyledLink = styled("a")`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
    height: 32px;
    display: none;
  }
  .desktop-icon {
    width: 160px;
    height: 32px;
    display: block;
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`;

{/* 修改点：左上角logo的尺寸 */ }
const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href, isDark, logoConfig }) => {
  const { logoSrc, logoWithTextSrc } = logoConfig;
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = useMemo(() => href.startsWith("http"), [href]);
  const innerLogo = useMemo(
    () => (
      <>
        {/* <LogoIcon className="mobile-icon" />
      <LogoWithTextIcon className="desktop-icon" /> */}
        <LogoImg src={logoSrc} className="mobile-icon" />

        {/* <LogoWithTextImg src={isDark ? logoWithTextSrc.replace(".png", "-dark.png") : logoWithTextSrc} className="desktop-icon" /> */}

        <LogoWithTextImg src={logoWithTextSrc} className="desktop-icon" width={208} height={40} />

      </>
    ),
    [logoSrc, logoWithTextSrc]
  );

  const innerLink = useMemo(
    () => (
      <StyledLink href={href} as={isAbsoluteUrl ? "a" : linkComponent} aria-label="Pancake home page">
        {innerLogo}
      </StyledLink>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [href, isAbsoluteUrl, innerLogo]
  );

  return <Flex>{innerLink}</Flex>;
};

export default React.memo(Logo);
