import { ChainId, ERC20Token, WTX } from '@pancakeswap/sdk'
import { USTX, USDT } from './common'

export const telegramXTokens = {
  wtx: WTX[ChainId.TELEGRAM_X],
  ustx: USTX[ChainId.TELEGRAM_X],
  usdt: USDT[ChainId.TELEGRAM_X],
  shib: new ERC20Token(
    ChainId.TELEGRAM_X,
    '0x5312d3c6bf732c5b3fbd2317f36ab6f8857633d8',
    18,
    'SHIB',
    'Shib lun',
    'https://telegramxdown.oss-cn-hongkong.aliyuncs.com/image/1caebdbed7a64bbfb065eae2177b7425SHIB.jpg',
  ),
  x10000: new ERC20Token(
    ChainId.TELEGRAM_X,
    '0x5aa19871172d4dead3a9342599ec5ef33ad563ae',
    18,
    'x10000',
    'x10000',
    'https://telegramxdown.oss-cn-hongkong.aliyuncs.com/image/x10000.png',
  ),
  luckydoge: new ERC20Token(
    ChainId.TELEGRAM_X,
    '0x64244dbfc65dc45518cbd7e9c29816f6422bb812',
    18,
    'luckydoge',
    'luckydoge',
    'https://telegramxdown.oss-cn-hongkong.aliyuncs.com/image/幸运狗LOGO.jpg',
  ),
  msc: new ERC20Token(
    ChainId.TELEGRAM_X,
    '0xe479e026c7ffc3e5e8bc54750900af3c3ceee63f',
    18,
    'MSC',
    'MetaStarCoin',
    'https://telegramxdown.oss-cn-hongkong.aliyuncs.com/image/b6e9ad5a7d704b79ac6a13f5864fbe4d1.jpg',
  ),
  mili: new ERC20Token(
    ChainId.TELEGRAM_X,
    '0xd61a2b12ec4b5aa79a2cc69dc8363c2c2425d7a1',
    9,
    'MILI',
    'MILI',
    'https://telegramxdown.oss-cn-hongkong.aliyuncs.com/image/b232cfde54a94edba1881f295903a8671ec4ce990ad8922979ef9e0f7a8003b.jpg',
  ),
}
