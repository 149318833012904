import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10,0.5 C11.1045695,0.5 12,1.3954305 12,2.5 L12,3.5 L14,3.5 C15.1045695,3.5 16,4.3954305 16,5.5 L16,13.5 C16,14.6045695 15.1045695,15.5 14,15.5 L2,15.5 C0.8954305,15.5 1.3527075e-16,14.6045695 0,13.5 L0,2.5 C-1.3527075e-16,1.3954305 0.8954305,0.5 2,0.5 L10,0.5 Z M13,5.5 L3,5.5 C2.48716416,5.5 2.06449284,5.88604019 2.00672773,6.38337887 L2,6.5 L2,12.5 C2,13.0128358 2.38604019,13.4355072 2.88337887,13.4932723 L3,13.5 L13,13.5 C13.5128358,13.5 13.9355072,13.1139598 13.9932723,12.6166211 L14,12.5 L14,6.5 C14,5.94771525 13.5522847,5.5 13,5.5 Z M12,8.5 C12.5522847,8.5 13,8.94771525 13,9.5 C13,10.0522847 12.5522847,10.5 12,10.5 C11.4477153,10.5 11,10.0522847 11,9.5 C11,8.94771525 11.4477153,8.5 12,8.5 Z M9,2.5 L3,2.5 C2.48716416,2.5 2.06449284,2.88604019 2.00672773,3.38337887 L2,3.5 L10,3.5 C10,2.94771525 9.55228475,2.5 9,2.5 Z"
        id="形状结合"
      />
    </Svg>
  );
};

export default Icon;
