import React from "react";
import Img from "./Image";
import { ImgProps } from "./types";

const LogoWithTextIcon: React.FC<ImgProps> = ({ className, src, height, width, ...props }) => {

  return <Img src={src} width={width ? width : 208} height={height ? height : 40} className={className} alt="test" />;
};

export default LogoWithTextIcon;
