import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { CommunityItemType } from '../types'
import { Box, Flex } from '../../Box'
import { useToast } from '../../../contexts'
import { LogoImg } from '../../Image'
import { Text } from '../../Text'
import { Heading } from '../../Heading'
import { Link } from '../../Link'

const StyledBox = styled(Box)`
  min-width: 291px;
  width: 100%;
  height: 154px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  ${({ theme }) => theme.mediaQueries.md} {
    
    width: 291px;
  }
 
`

const StyledHeading = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`
const StyledText = styled(Text)`
  display: -webkit-box;
  -webkitLine-clamp: 7;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: none!important;
  }
`


const StyleLink = styled(Link)`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.md} {
    
    width: fit-content;
  }
`

export function CommunityItem({ data }: { data: CommunityItemType }) {
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  return (
    <StyleLink external color='text' key={data.redirectUrl} href={data.redirectUrl} aria-label={data.redirectUrl}>
      <StyledBox >


        <Flex flexDirection='column' alignItems='flex-start' style={{ gap: '8px', width: '100%' }} flex='1 0'>
          <Flex style={{ gap: '8px' }} alignItems='center' flex='1' width='100%'>
            <LogoImg src={data?.logoUrl} width={32} height={32} style={{ borderRadius: '4px', overflow: 'hidden' }} />
            <StyledHeading color='text' >{data?.communityName} </StyledHeading>
          </Flex>
          <StyledText fontSize='12px' maxHeight='98px' lineHeight='14px' color='textSubtle' fontWeight='400' overflow='hidden' >{data?.communityDes}</StyledText>
        </Flex>




      </StyledBox >
    </StyleLink>
  )
}

