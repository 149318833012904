import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { CommunityItem } from './CommunityItem'
import { CommunityItemType } from '../types'
import { Flex } from '../../Box'



const Container = styled(Flex)`
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  padding:  12px 0;
`



export function CommunityList({ data }: { data: CommunityItemType[] }) {
  const { t } = useTranslation()

  return (
    <Container>
      {data.map((item, index) => (<CommunityItem data={item} />))}
    </Container>
  )
}
