import { atom, useAtomValue } from 'jotai'
import { isAddress } from 'utils'
import { useNewRouterContract } from 'utils/exchange'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useMemo } from 'react'
import { useTokenContract } from './useContract'
import { useActiveChainId } from './useActiveChainId'
import useActiveWeb3React from './useActiveWeb3React'

const queryInviterAtom = atom('')

queryInviterAtom.onMount = (set) => {
  const params = new URL(window.location.href).searchParams
  const c = params.get('i') || ''
  if (isAddress(c)) {
    set(c)
  }
}

export const useInviter = () => {
  const queryInviter = useAtomValue(queryInviterAtom)

  return queryInviter
}

export function useInviteCount(): string {
  const { account } = useActiveWeb3React()
  const newContract = useNewRouterContract()

  const inviteCount: string | undefined =
    useSingleCallResult(newContract, 'inviteCount', [account])?.result?.[0]?.toString() || '0'
  const inviteCount2: string | undefined =
    useSingleCallResult(newContract, 'inviteCount2', [account])?.result?.[0]?.toString() || '0'
  const num = useMemo(() => {
    return (inviteCount && inviteCount2 && `${inviteCount} (${inviteCount2})`) || '0 (0)'
  }, [inviteCount, inviteCount2])
  return num
}
