import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

export function useGlobalKeepSearchParams<T extends string>(keys: T[]): void {
  const [fixedSearchParams, setFixedSearchParams] = useState<Partial<Record<T, string>>>({})
  const { pathname, query } = useRouter()
  const router = useRouter()
  const queryKeys = Object.keys(query)

  useEffect(() => {
    // console.log('useGlobalKeepSearchParams effect called')

    const nextFixedSearchParams: typeof fixedSearchParams = {}
    let setSearchParamsFlag = false

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const value = query[key]
      const fixedValue = fixedSearchParams[key]

      // 保存固定参数
      if (fixedValue == null && value != null) {
        nextFixedSearchParams[key] = value as string
        // todo: 这里next支持数组行的query 这里不存在忽略
      }

      if (fixedValue !== value) {
        query[key] = fixedValue
        setSearchParamsFlag = true
      }
    }

    if (Object.keys(nextFixedSearchParams).length) {
      setFixedSearchParams(nextFixedSearchParams)
    }

    if (setSearchParamsFlag) {
      router.replace(router, undefined, { scroll: false })

      // console.log('useGlobalKeepSearchParams effect called, search params set')
    }
  }, [pathname, fixedSearchParams, queryKeys, keys, router])
}
