import '@pancakeswap/ui/css/reset.css'
import { ResetCSS, ToastListener } from '@pancakeswap/uikit'
import { useDebounceEffect } from 'ahooks'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { NetworkModal } from 'components/NetworkModal'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import useSentryUser from 'hooks/useSentryUser'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { initMapleRedPacket } from '@maple-tech/red-packet-sdk'
import { Fragment, useEffect } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import TransactionsDetailModal from 'components/TransactionDetailModal'
import { SubSwapName } from 'config/constants/subswap'
import { SubSwapType, SwapName } from 'config/constants/exchange'
import { useGlobalKeepSearchParams } from 'hooks/useRouterHelper'
import { Blocklist, Updaters } from '..'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'


// import './maple.css'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  useGlobalKeepSearchParams(['i'])
  return null
}

function MPGlobalHooks() {
  usePollBlockNumber()
  useEagerConnectMP()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useGlobalKeepSearchParams(['i'])
  return null
}

// // 
// function switchFavicon() {
//   // 如果subswapName是keke，就切换成keke的favicon
//   if ((SubSwapName as SubSwapType) === SubSwapType.KEKE) {
//     return '/favicon-keke.ico'
//   }
//   return '/favicon.ico'
// }

// function switchAppleTouchIcon() {
//   // 如果subswapName是keke，就切换成keke的apple-touch-icon
//   if ((SubSwapName as SubSwapType) === SubSwapType.KEKE) {
//     return `https://d1l7ift7wyu6at.cloudfront.net/images/logos/logo-${SubSwapName}.png`
//   }
//   return 'https://d1l7ift7wyu6at.cloudfront.net/images/logos/logo.png'
// }

// 修改点：主界面icon等,修改这两个地方
//  <link rel="apple-touch-icon" href="https://d1l7ift7wyu6at.cloudfront.net/images/logos/logo.png" /> tp收藏时候的图标
//  <link rel="shortcut icon" href="/favicon.ico" /> 浏览器收藏时候的图标

function switchTitle() {
  if (SwapName[SubSwapName as SubSwapType] === undefined) {
    return 'BitSwap'
  }
  return SwapName[SubSwapName as SubSwapType];
}



function MyApp(props: AppProps<{ initialReduxState: any }>) {
  const { pageProps, Component } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta
          name="description"
          content="Cheaper and faster than Uniswap? Discover BitSwap, the leading DEX on BlockChain."
        />
        <meta name="theme-color" content="#1FC7D4" />
        <meta property="og:title" content={switchTitle()} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="A next evolution DeFi exchange on EveryChain." />
        <link rel="stylesheet" href="/maple.css" />
        <link rel="apple-touch-icon" href="https://d1l7ift7wyu6at.cloudfront.net/images/logos/logo.png" />

        <link rel="shortcut icon" href="/favicon.ico" />
        <title>{switchTitle()}</title>
        {(Component as NextPageWithLayout).mp && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
        )}
      </Head>
      <Providers store={store}>
        <Blocklist>
          {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu

  return (
    <ProductionErrorBoundary>
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      <NetworkModal pageSupportedChains={Component.chains} />
      <TransactionsDetailModal />
    </ProductionErrorBoundary>
  )
}

export default MyApp
