import { useTranslation } from '@pancakeswap/localization'
import { useRequest } from 'ahooks'
import axios from 'axios'
import { useMemo } from 'react'

export const useCommunityLinkData = () => {
  const { t } = useTranslation()
  const { loading, data, refresh } = useRequest(
    () => {
      return axios.get(`${process.env.NEXT_PUBLIC_ADAPI}/api/doge/community?pl=bitchain`)
    },
    {
      cacheKey: `/api/doge/community?pl=bitchain`,
      debounceWait: 300,
      retryCount: 3,
      refreshDeps: [],
    },
  )
  const communityLink = useMemo(() => {
    const _data = data?.data
    if (_data?.data?.list && _data.data.list.length > 0) {
      return {
        label: t('Collaborative Community'),
        items: _data.data.list,
      }
    }
    return undefined
  }, [data?.data, t])
  return { loading, data: communityLink, refresh }
}
