import { ChainId, JSBI, Percent, Token, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDC,
  USDT,
  BUSD,
  WBTC_ETH,
  telegramXTokens,
  USDB,
  bitTokens,
} from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'
import { SubSwapName } from './subswap'

export const enum SubSwapType {
  MAIN = 'main',
  BIT = 'bit',
  BITSWAP = 'bitswap',
  KEKE = 'keke',
  DEV = 'dev',
  BTN10086 = 'btn10086',
}

// Swap名称，默认为BitSwap
export const SwapName = {
  [SubSwapType.BIT]: 'BitSwap',
  [SubSwapType.BITSWAP]: 'BitSwap',
  [SubSwapType.KEKE]: 'KEKESwap',
  [SubSwapType.BTN10086]: 'BitSwap',
}

// logo图片的后缀，默认为空
export const LogoSuffix = {
  [SubSwapType.KEKE]: 'keke',
}

// exchange.ts
// 修改点：Router地址

// 配置路由地址
const BSCROUTER = {
  main: '0xEdDb16da43daed83158417955dc0c402C61e7E7D',
  btn10086: '0xF7fA7DD3Fb0cB00F994fCca434F9796D9F803aB4',
  bit: '0xFf42dfC39578F24Cb64B0B29eAaa44Bf8B5c4443',
  dev: '0xEdDb16da43daed83158417955dc0c402C61e7E7D',
  bitswap: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  keke: '0xa43d8cfa2Ef660EfE99490825332886b1FabAA77',
}
const NEW_BSCROUTER = {
  main: '0xEdDb16da43daed83158417955dc0c402C61e7E7D',
  btn10086: '0xF7fA7DD3Fb0cB00F994fCca434F9796D9F803aB4',
  bit: '0xFf42dfC39578F24Cb64B0B29eAaa44Bf8B5c4443',
  dev: '0xEdDb16da43daed83158417955dc0c402C61e7E7D',
  bitswap: '0x0dDa32Ef300c3B1902B1b1f2b7E9145E245E8e7b',
  keke: '0xa43d8cfa2Ef660EfE99490825332886b1FabAA77',
}

const ETHROUTER = {
  main: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  btn10086: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  bit: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  dev: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  bitswap: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  keke: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
}
const NEW_ETHROUTER = {
  main: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  btn10086: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  bit: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  dev: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
  bitswap: '0xECC5A3C54f85ab375dE921A40247D726bc8eD376',
  keke: '0xfE277C6B684e5ed527Ae36c4fcfc7014302fe45e',
}

// btn10086.bitxswap.biz

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.BIT]: '0xA15365c3ce84b2cc9D4D5C612c202354e4Cc2c1F',
  [ChainId.ETHEREUM]: ETHROUTER[SubSwapName],
  [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  [ChainId.BASE]: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
  [ChainId.X_LAYER]: '0x881fB2f98c13d521009464e7D1CBf16E1b394e8E',
  [ChainId.GOERLI_BASE]: '0xDB240d69F323b6Bc3f4BA73659ae22A64964902b',
  [ChainId.GOERLI]: '0xd149f21Fe15f4e1cc02F88433832a0295B1453e6',
  [ChainId.BSC]: BSCROUTER[SubSwapName],
  [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  [ChainId.TELEGRAM_X]: '0xAc9857519069b05eC338e486FcD7bfCc79B68D5a',
  // [ChainId.TELEGRAM_X]: '0x572a8Dd28Df02182B97deac1d922b3EAC349b7f7',
}

export const NEW_ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.BIT]: '0xA15365c3ce84b2cc9D4D5C612c202354e4Cc2c1F',
  [ChainId.ETHEREUM]: NEW_ETHROUTER[SubSwapName],
  [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  [ChainId.BASE]: '0x5B3A8E7570c24e359111b7AFEFA7006d10B4e4D8',
  [ChainId.X_LAYER]: '0x5B3A8E7570c24e359111b7AFEFA7006d10B4e4D8',
  [ChainId.GOERLI_BASE]: '0xDB240d69F323b6Bc3f4BA73659ae22A64964902b',
  [ChainId.GOERLI]: '0xd149f21Fe15f4e1cc02F88433832a0295B1453e6',
  [ChainId.BSC]: NEW_BSCROUTER[SubSwapName],
  [ChainId.BSC_TESTNET]: '0xD2c5924456db41B4fe10f5f3884a940A84056111',
  [ChainId.TELEGRAM_X]: '0xAc9857519069b05eC338e486FcD7bfCc79B68D5a',
  // [ChainId.TELEGRAM_X]: '0xb2d6Af4587ce2d0E65ed48377D14f85Ba72dD503',
}

export const DOMAIN_NAME: ChainMap<string> = {
  [ChainId.BIT]: 'BTC LPs',
  [ChainId.ETHEREUM]: 'Uniswap V2',
  [ChainId.RINKEBY]: 'Pancake LPs',
  [ChainId.GOERLI_BASE]: 'Base LPs',
  [ChainId.BASE]: 'Base LPs',
  [ChainId.GOERLI]: 'Uniswap V2',
  [ChainId.BSC]: 'Pancake LPs',
  [ChainId.BSC_TESTNET]: 'Pancake LPs',
  [ChainId.TELEGRAM_X]: 'TXMaple LPs',
  [ChainId.X_LAYER]: 'Potato LPs',
}

export const LP_SYMBOL: ChainMap<string> = {
  [ChainId.BIT]: 'WBTC-LP',
  [ChainId.ETHEREUM]: 'UNI-V2',
  [ChainId.RINKEBY]: 'Cake-LP',
  [ChainId.GOERLI_BASE]: 'Base-LP',
  [ChainId.BASE]: 'Base-LP',
  [ChainId.GOERLI]: 'UNI-V2',
  [ChainId.BSC]: 'Cake-LP',
  [ChainId.BSC_TESTNET]: 'Cake-LP',
  [ChainId.TELEGRAM_X]: 'TXMaple-LP',
  [ChainId.X_LAYER]: 'Pot-LP',
}

export const CHAIN_TYPE: ChainMap<string> = {
  [ChainId.BIT]: 'bit',
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.RINKEBY]: 'rinkeby',
  [ChainId.GOERLI_BASE]: 'base',
  [ChainId.BASE]: 'goerli_base',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bsc_testnet',
  // [ChainId.TELEGRAM_X]: '0x572a8Dd28Df02182B97deac1d922b3EAC349b7f7',
  // [ChainId.TELEGRAM_X]: '0x0B0B21a09eB235c04cD21b138bCc0A7ff83c5ac6',
  [ChainId.TELEGRAM_X]: 'tx',
  [ChainId.X_LAYER]: 'x_layer',
}

// 金刚区？？？
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.BIT]: [USDB[ChainId.BIT], bitTokens.bitx, WNATIVE[ChainId.BIT]],
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.RINKEBY], USDC[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDC[ChainId.BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.usdt, bscTokens.busd, bscTokens.btcb, bscTokens.eth, bscTokens.usdc],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [telegramXTokens.wtx, telegramXTokens.ustx, telegramXTokens.usdt],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
// 修改点： 自定义路由，注意要双向
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
    [bscTokens.snfts.address]: [bscTokens.sfund],
  },
  [ChainId.BIT]: {
    [bitTokens.bitx.address]: [WNATIVE[ChainId.BIT]],
    [bitTokens.bitx.address]: [bitTokens.brc],
    [WNATIVE[ChainId.BIT].address]: [bitTokens.bitx],
    [bitTokens.brc.address]: [bitTokens.bitx],
    [bitTokens.usdb.address]: [bitTokens.bitx],
    [bitTokens.bitx.address]: [bitTokens.usdb],
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
// 只能用此币对交易，注意双向
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
  // [ChainId.BIT]: {[bitTokens.bitx.address]: [bitTokens.brc,WNATIVE[ChainId.BIT]]},
}

// 金刚区
// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.BIT]: [USDB[ChainId.BIT], bitTokens.bitx, WNATIVE[ChainId.BIT]],
  [ChainId.ETHEREUM]: [USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], WBTC_ETH, USDC[ChainId.ETHEREUM]],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDC[ChainId.BASE]],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.busd, bscTokens.btcb],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BIT]: [WNATIVE[ChainId.BIT], USDB[ChainId.BIT]],
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.GOERLI_BASE]: [WNATIVE[ChainId.GOERLI_BASE]],
  [ChainId.BASE]: [WNATIVE[ChainId.BASE], USDC[ChainId.BASE]],
  [ChainId.X_LAYER]: [WNATIVE[ChainId.X_LAYER], USDT[ChainId.X_LAYER]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.busd],
  [ChainId.TELEGRAM_X]: [telegramXTokens.wtx, telegramXTokens.ustx, telegramXTokens.usdt],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBTC_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.cake, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
  [ChainId.TELEGRAM_X]: [[telegramXTokens.wtx, telegramXTokens.usdt]],
  // [ChainId.BASE]: [[WNATIVE[ChainId.BASE], USDT[ChainId.BASE]]],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(6000), BIPS_BASE) // 原始10%，现在改为60%，以下不需要输入confirm
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// 基础费率，原始千分之25，现在千分之105
export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
// CAKE 默认代币合约
export const DEFAULT_OUTPUT_CURRENCY = ''

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.swap.bitchain.biz/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
