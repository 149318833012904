import { ChainId, WBTC, ERC20Token, WETH9 } from '@pancakeswap/sdk'

export const USDB = {
  [ChainId.BIT]: new ERC20Token(ChainId.BIT, '0xe3d493273b8274a86c3a5609eb17d8df5f47ce49', 18, 'USDB', 'USDB', ''),
}
export const bitTokens = {
  wbtc: WBTC[ChainId.BIT],
  usdb: USDB[ChainId.BIT],
  eth: WETH9[ChainId.BIT],
  brc: new ERC20Token(ChainId.BIT, '0xF89c8c3cf0D39745f9F691fc2839572dDc00e02f', 18, 'BRC', 'BRC', ''),
  bitx: new ERC20Token(ChainId.BIT, '0x2075f6234Ed744dCb686c5D3e867c9f2deb58De6', 18, 'BITX', 'BITX', ''),

}
